<template>
  <div class="boxBox">
    <div class="searchBox" :style="{ opacity: config.isConfigMode ? 0.5 : 1, pointerEvents: config.isConfigMode ? 'none' : 'auto' }">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="进仓单号">
          <el-input v-model="dataForm.inWarehouseNo" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="车牌号">
          <el-input v-model="dataForm.vehiclePlate" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="提货时间">
          <el-date-picker
            v-model="dataForm.takeGoodsTime"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="-"
            size="small"
            @change="getDataList()"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select class="item-choose" v-model="dataForm.status" size="small" @change="getDataList()" filterable clearable placeholder="请选择">
            <el-option label="全部" value="" ></el-option>
            <el-option v-for="item in dictTypeMap.orderStatusMap" :key="item.id" :label="item.name" :value="item.id" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户名称" v-if="newAuth('order:customer:findAll')">
          <el-input v-model="dataForm.customerName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="客户联系人">
          <el-input v-model="dataForm.customerContactName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button size="small" type="success" v-if="newAuth('order:customer:export')" @click="downLoad">导出</el-button>
          <el-button size="small" type="warning" v-if="newAuth('order:customer:entranceOut')" @click="entranceOut">已报出</el-button>
          <el-button size="small" type="danger" v-if="newAuth('order:customer:entranceIn')" @click="entranceIn">已报入</el-button>
        </el-form-item>
        <el-form-item style="text-align: right; display: flex; justify-content: flex-end; align-items: center;">
          <el-button size="small" @click="templateConfigurationMode(0)" type="warning">模板配置模式</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox" :style="{ position: 'relative' }">
      <!-- 配置模式 -->
      <el-row v-if="config.isConfigMode" style="justify-self: center;padding: 10px 10px">
        <el-button size="mini" @click="config.isConfigMode = false" type="danger">取消配置模式</el-button>
        <el-button v-no-more-click size="mini" @click="templateConfigurationMode(1)" type="success">保存模板</el-button>
        <el-button v-no-more-click size="mini" @click="templateConfigurationMode(2)" type="warning">修改模板</el-button>
        <el-button v-no-more-click size="mini" @click="$refs.configTableRef.init(config.configTableId)" type="warning">所有模板</el-button>
        <el-popover placement="right" width="520" trigger="click">
          <div style="justify-self: center;">
            <el-button v-no-more-click @click="checkAllNodes(true)" size="mini">全选</el-button>
            <el-button v-no-more-click @click="checkAllNodes(false)" size="mini">全不选</el-button>
            <el-tooltip class="item" effect="dark" content="可拖拽必须先取消固定才允许拖拽排列,如果有固定固定按钮会显示为绿色" placement="top-start">
              <i class="el-icon-view el-icon--right" />
            </el-tooltip>
          </div>
          <br/>
          <el-tree :data="config.configDefaultColumn"
                   style="max-height: 200px;overflow-y: auto;"
                   default-expand-all
                   show-checkbox
                   draggable
                   node-key="prop"
                   ref="configTableListTree"
                   @check-change="handleCheckChange"
                   @node-drop="handleDrop"
                   :allow-drag="allowDrag"
                   :allow-drop="allowDrop">
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                    <el-row :gutter="20">
                       <el-col :span="16">
                         <div class="grid-content bg-purple">
                           <el-input size="mini" placeholder="列头名" v-model="data.label"></el-input>
                         </div>
                       </el-col>
                       <el-col :span="8">
                         <div class="grid-content bg-purple">
                           <el-link :type="data.fixed && data.fixed === 'left' ? 'success' : 'info'" @click="data.fixed = 'left'" :underline="false" >固定左侧</el-link>
                           <el-link :type="data.fixed && data.fixed === 'right' ? 'success' : 'info'" @click="data.fixed = 'right'" :underline="false" >固定右侧</el-link>
                           <el-link :disabled="!data.fixed" :underline="false" @click="data.fixed = undefined" >取消固定</el-link>
                         </div>
                       </el-col>
                    </el-row>
                  </span>
          </el-tree>
          <el-button style="margin-left: 10px;" size="mini" slot="reference" icon="el-icon-s-tools" circle />
        </el-popover>
        <br/>
        <br/>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <el-input v-model="config.configModeForm.name" size="mini" placeholder="模板名称" clearable />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-light">
              <el-input v-model="config.configModeForm.remark" size="mini" placeholder="备注" clearable />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <el-switch
                  v-model="config.configModeForm.defaultOrNot"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="'1'"
                  :inactive-value="'0'">
              </el-switch>
            </div>
          </el-col>
        </el-row>
      </el-row>

      <el-table :row-class-name="tableRowClassName"
                ref="tableRef"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                :data="dataList"
                border size="small"
                height="72vh"
                :span-method="arraySpanMethod"
                @selection-change="selectionChangeHandle"
                v-loading="dataListLoading" style="width: 100%;">
        <el-table-column type="selection" width="40" fixed="left" align="center"></el-table-column>
        <el-table-column
            v-for="(item, index) in config.configDefaultColumn"
            :key="index"
            align="left"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            :fixed="item.fixed"
            :show-overflow-tooltip="true"
            v-if="item.check"
        >
          <template slot-scope="scope">
            <!-- 货物尺寸(长/宽/高) -->
            <template v-if="item.prop == 'orderGoods.length'">
              {{ scope.row.orderGoods.length }} / {{ scope.row.orderGoods.width }} / {{ scope.row.orderGoods.height }}
            </template>
            <!-- 装货图片 -->
            <template v-else-if="item.prop == '装货图片'">
              <span @click="viewPhoto(scope.row, loadGoodsTypeList)" style="color: blue;cursor:pointer" v-if="scope.row.orderInfo.showLoadImage">查看</span>
              <span v-else>无</span>
            </template>
            <!-- 签收图片 -->
            <template v-else-if="item.prop == '签收图片'">
              <span @click="viewPhoto(scope.row, successTypeList)" style="color: blue;cursor:pointer" v-if="scope.row.orderInfo.showSuccessImage">查看</span>
              <span v-else>无</span>
            </template>
            <!-- 出车时间 -->
            <template v-else-if="item.prop == 'outDriveTimeOsr'">
              <span style="color: red" v-if="isWithinFiveMinutes(scope.row.outDriveTimeOsr, scope.row.arrivedTimeOsr)">{{ scope.row.outDriveTimeOsr }}</span>
              <span v-else>{{ scope.row.outDriveTimeOsr }}</span>
            </template>
            <!-- 装货到场时间 -->
            <template v-else-if="item.prop == 'arrivedTimeOsr'">
              <span style="color: red" v-if="isWithinFiveMinutes(scope.row.arrivedTimeOsr, scope.row.loadGoodsTimeOsr) || isWithinFiveMinutes(scope.row.outDriveTimeOsr, scope.row.arrivedTimeOsr)">{{ scope.row.arrivedTimeOsr }}</span>
              <span v-else>{{ scope.row.arrivedTimeOsr }}</span>
            </template>
            <!-- 装货离场时间 -->
            <template v-else-if="item.prop == 'loadGoodsTimeOsr'">
              <span style="color: red" v-if="isWithinFiveMinutes(scope.row.loadGoodsTimeOsr, scope.row.arrivedGiveTimeOsr) || isWithinFiveMinutes(scope.row.arrivedTimeOsr, scope.row.loadGoodsTimeOsr)">{{ scope.row.loadGoodsTimeOsr }}</span>
              <span v-else>{{ scope.row.loadGoodsTimeOsr }}</span>
            </template>
            <!-- 卸货到场时间 -->
            <template v-else-if="item.prop == 'arrivedGiveTimeOsr'">
              <span style="color: red" v-if="isWithinFiveMinutes(scope.row.arrivedGiveTimeOsr, scope.row.orderInfo.successTime) || isWithinFiveMinutes(scope.row.loadGoodsTimeOsr, scope.row.arrivedGiveTimeOsr)">{{ scope.row.arrivedGiveTimeOsr }}</span>
              <span v-else>{{ scope.row.arrivedGiveTimeOsr }}</span>
            </template>
            <!-- 签收时间 -->
            <template v-else-if="item.prop == 'orderInfo.successTime'">
              <span style="color: red" v-if="isWithinFiveMinutes(scope.row.orderInfo.successTime, scope.row.arrivedGiveTimeOsr)">{{ scope.row.orderInfo.successTime }}</span>
              <span v-else>{{ scope.row.orderInfo.successTime }}</span>
            </template>
            <!-- 状态 -->
            <template v-else-if="item.prop == 'status'">
              <el-tag v-if="scope.row.orderInfo.status == 0 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">未提交</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 1 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="warning">待审核</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 2 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="warning" effect="plain">调度中</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 3 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="primary">已调度</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 8 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="success">已完成</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 9 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="info" effect="dark">异常结束</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 10 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger" effect="dark">审核拒绝</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 11 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger" effect="plain">订单退回</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 12 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="info">已取消</el-tag>
              <el-tag v-else-if="scope.row.status == 4" size="small" color="#409eff00" plain>待出车</el-tag>
              <el-tag v-else-if="scope.row.status == 5" size="small" type="success" effect="plain">已出车</el-tag>
              <el-tag v-else-if="scope.row.status == 6" size="small" type="success" color="#d8ff7bdb">已到达</el-tag>
              <el-tag v-else-if="scope.row.status == 7" size="small" type="success" color="#ffff78">已装货</el-tag>
              <el-tag v-else-if="scope.row.status == 15" size="small" type="success" effect="dark">卸货到场</el-tag>
            </template>
            <!-- 报出状态 -->
            <template v-else-if="item.prop == 'orderInfo.entranceOutStatus'">
              <el-tag v-if="scope.row.orderInfo.entranceOutStatus == 1" size="small" type="success">已报出</el-tag>
              <el-tag v-else size="small">无</el-tag>
            </template>
            <!-- 报入状态 -->
            <template v-else-if="item.prop == 'orderInfo.entranceInStatus'">
              <el-tag v-if="scope.row.orderInfo.entranceInStatus == 1" size="small" type="success">已报入</el-tag>
              <el-tag v-else size="small">无</el-tag>
            </template>
            <template v-else>
              {{ getFormatter(item.formatter)(scope.row, item, _.get(scope.row, item.prop)) }}
            </template>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="left" label="操作" prop="operate" width="40">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="newAuth('order:customer:find')" type="text" size="small" @click="addOrUpdateHandle(scope.row, 'view')">详情</el-button>
              <el-button v-if="newAuth('order:customer:cost')" type="text" size="small" @click="customerCostDetail('cost', 'view', scope.row)">费用信息</el-button>
              <el-button v-if="newAuth('order:customer:copy')" type="text" size="small" @click="copyInfo(scope.row)">复制</el-button>
              <el-button v-if="showButton(scope.row, 'commit', 'order:customer:commit')" type="text" size="small" @click="updateStatus(scope.row, '提交', 1)">提交</el-button>
              <el-button v-if="showButton(scope.row, 'update', 'order:customer:update')" type="text" size="small" @click="addOrUpdateHandle(scope.row, 'edit')">修改</el-button>
              <el-button v-if="showButton(scope.row, 'cancel', 'order:customer:cancel')" type="text" size="small" @click="updateStatus(scope.row, '取消', 12)">取消订单</el-button>
              <el-button v-if="newAuth('order:customer:find')" type="text" size="small" @click="trackView(scope.row)">轨迹查看</el-button>
              <el-button v-if="newAuth('order:customer:delete')" type="text" size="small" @click="deleteHandle(scope.row)">删除</el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>


    <!-- 弹窗, 新增 / 修改 -->
    <cancel-order-pop ref="cancel"></cancel-order-pop>
    <!-- 查看图片 -->
    <view-image ref="viewPhoto"></view-image>
    <import-drawer ref="drawer"></import-drawer>
    <!-- 查看轨迹 -->
    <iframe-track ref="track"></iframe-track>
    <customer-cost ref="customerCost"></customer-cost>

    <!-- 模板配置弹窗 -->
    <configTable ref="configTableRef" />
  </div>
</template>

<script>
import configTable from "@/components/table";
import cancelOrderPop from './customerPop/cancelOrderPop'
import viewImage from '@/views/commonPop/viewImage'
import iframeTrack from '@/views/commonPop/iframe-track'
import importDrawer from './customerPop/importDrawer'
import customerCost from './customerPop/customerCostPop'
import qs from 'qs'
import * as myJsonClon from "@/utils/myJsonClone";
export default {
  components: {
    configTable,
    cancelOrderPop,
    viewImage,
    importDrawer,
    iframeTrack,
    customerCost
  },
  data () {
    return {
      // -----------------动态表格模式start-----------------
      config: {
        configId: null,
        configTableId: 3,
        configDefaultColumn: [
          {label: '客户名称',prop: 'orderInfo.customerName',width: '100',check: true,fixed: undefined},
          {label: '客户联系人',prop: 'orderInfo.customerContactName',width: '70',check: true,fixed: undefined},
          {label: '提货时间',prop: 'deliveryTimeNode',width: '105',check: true,fixed: undefined},
          {label: '进仓单号',prop: 'orderInfo.inWarehouseNo',width: '70',check: true,fixed: undefined},
          {label: '订单类型',prop: 'orderInfo.orderType',width: '60',formatter: 'orderType',check: true,fixed: undefined},
          {label: '所需车型',prop: 'orderInfo.vehicleType',width: '75',formatter: 'dictConvertVehicle',check: true,fixed: undefined},
          {label: '司机车牌',prop: 'vehiclePlan.vehiclePlate',width: '70',check: true,fixed: undefined},
          {label: '报关方式',prop: 'orderInfo.entranceType',width: '75',formatter: 'entranceType',check: true,fixed: undefined},
          {label: '提货方',prop: 'factoryName',width: '80',check: true,fixed: undefined},
          {label: '提货地址',prop: 'fullAddress',width: '180',check: true,fixed: undefined},
          {label: '提货联系人',prop: 'contactName',width: '70',check: true,fixed: undefined},
          {label: '提货电话',prop: 'contactPhone',width: '85',check: true,fixed: undefined},
          {label: '卸货方',prop: 'giveAddressInfo.factoryName',width: '75"',check: true,fixed: undefined},
          {label: '收货地址',prop: 'giveAddressInfo.fullAddress',width: '180"',check: true,fixed: undefined},
          {label: '收货联系人',prop: 'giveAddressInfo.contactName',width: '70"',check: true,fixed: undefined},
          {label: '收货电话',prop: 'giveAddressInfo.contactPhone',width: '85"',check: true,fixed: undefined},
          {label: '件数',prop: 'orderGoods.pieceNum',width: '40"',check: true,fixed: undefined},
          {label: '体积',prop: 'orderGoods.volume',width: '40"',check: true,fixed: undefined},
          {label: '重量',prop: 'orderGoods.weight',width: '40"',check: true,fixed: undefined},
          {label: '货物尺寸(长/宽/高)',prop: 'orderGoods.length',width: '110"',check: true,fixed: undefined},
          {label: '包装类型',prop: 'orderGoods.packType',width: '80',formatter: 'manyDictConvert',check: true,fixed: undefined},
          {label: '异常备注',prop: 'orderInfo.groupExplain',width: '80"',check: true,fixed: undefined},
          {label: '车辆空重(kg)',prop: 'vehiclePlan.vehicleEmptyWeight',width: '95"',check: true,fixed: undefined},
          {label: '司机姓名',prop: 'vehiclePlan.driverName',width: '60"',check: true,fixed: undefined},
          {label: '司机电话',prop: 'vehiclePlan.driverPhone',width: '85"',check: true,fixed: undefined},
          {label: '身份证号',prop: 'vehiclePlan.driverCard',width: '130"',check: true,fixed: undefined},
          {label: '是否尾板',prop: 'orderInfo.isTailstock',width: '70"',formatter: 'yesNo',check: true,fixed: undefined},
          {label: '订单号',prop: 'orderInfo.orderNo',width: '105"',check: true,fixed: undefined},
          {label: '客户单号',prop: 'orderInfo.customerSelfNo',width: '70"',check: true,fixed: undefined},
          {label: '客户联系电话',prop: 'orderInfo.customerContactPhone',width: '90"',check: true,fixed: undefined},
          {label: '装货图片',prop: '装货图片',width: '70"',check: true,fixed: undefined},
          {label: '签收图片',prop: '签收图片',width: '70"',check: true,fixed: undefined},
          {label: '下单时间',prop: 'orderInfo.commitTime',width: '120"',check: true,fixed: undefined},
          {label: '下单人',prop: 'orderInfo.commitName',width: '70"',check: true,fixed: undefined},
          {label: '派车时间',prop: 'orderInfo.dispatchTime',width: '120"',check: true,fixed: undefined},
          {label: '出车时间',prop: 'outDriveTimeOsr',width: '120"',check: true,fixed: undefined},
          {label: '装货到场时间',prop: 'arrivedTimeOsr',width: '120"',check: true,fixed: undefined},
          {label: '装货离场时间',prop: 'loadGoodsTimeOsr',width: '120"',check: true,fixed: undefined},
          {label: '卸货到场时间',prop: 'arrivedGiveTimeOsr',width: '120"',check: true,fixed: undefined},
          {label: '签收时间',prop: 'orderInfo.successTime',width: '120"',check: true,fixed: undefined},
          {label: '状态',prop: 'status',fixed: 'right',width: '58"',check: true},
          {label: '报出状态',prop: 'orderInfo.entranceOutStatus',fixed: 'right',width: '60"',check: true},
          {label: '报出时间',prop: 'orderInfo.clickOutTime',fixed: 'right',width: '85"',check: true},
          {label: '报入状态',prop: 'orderInfo.entranceInStatus',fixed: 'right',width: '60"',check: true},
          {label: '报入时间',prop: 'orderInfo.clickInTime',fixed: 'right',width: '70"',check: true},
        ],
        configDefaultPropColumn: [],
        isConfigMode: false,
        configModeForm: {
          name: '',
          remark: '',
          defaultOrNot: '0'
        },
      },
      // -----------------动态表格模式end-----------------

      dataForm: {
        inWarehouseNo: null,
        customerSelfNo: null,
        customerId: null,
        customerName: null,
        customerContactName: null,
        takeGoodsTime: [],
        status: null,
        vehiclePlate: null,
        customerIds: []
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      getIndex: -1,
      totalPage: 0,
      successTypeList: [6010],
      loadGoodsTypeList: [6011],
      dataListLoading: false,
      dataListSelections: [],
      spanArr: [],
      addOrUpdateVisible: false,
      detailVisible: false,
      enclosureInfoList: [],
      listType: 1
    }
  },
  created () {
    this.initConfigTable()

    this.setDefaultDate()
  },
  activated () {
    if (!this.newAuth('order:customer:findAll')) { // 不查看所有的时候，判断所属客户
      this.dataForm.customerId = this.currentUser.customerId
      this.dataForm.customerIds = this.currentUser.customerIdList
    }
    this.getDataList()
  },
  computed: {
    currentUser: {
      get () { return this.$store.state.user.currentUser }
    },
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    // -----------------动态表格模式start-----------------
    initConfigTable() {
      this.$http({
        url: this.$http.adornUrl('/tabulation/default/' + this.config.configTableId),
        method: 'get',
        params: this.$http.adornParams()
      }).then(async ({ data }) => {
        if (data) {
          this.config.configModeForm.name = data.name
          this.config.configModeForm.remark = data.remark
          this.config.configModeForm.defaultOrNot = data.defaultOrNot
          this.config.configId = data.id
          if (data.tmsTabulationConfigValueList && data.tmsTabulationConfigValueList.length > 0) {
            this.assignPropertiesLarge(this.config.configDefaultColumn, data.tmsTabulationConfigValueList,'prop',['label','fixed','width'])
            this.config.configDefaultColumn = this.reorderArray(this.config.configDefaultColumn, data.tmsTabulationConfigValueList, 'prop');
          }
        }
        this.config.configDefaultColumn.forEach(x => {this.config.configDefaultPropColumn.push({prop: x.prop})})
      })

    },
    assignPropertiesLarge (arr1, arr2, compareProp, assignProps) {
      const map = new Map();
      arr2.forEach(item => {
        map.set(item[compareProp], item);
      });
      arr1.forEach(item => {
        const match = map.get(item[compareProp]);
        if (match) {
          assignProps.forEach(prop => {
            item[prop] = match[prop];
          });
          item.check = true
        } else {
          item.check = false
        }
      });
      return arr1;
    },
    reorderArray(arr1, arr2, key) {
      if (arr2.length === 0) {
        return arr1;
      }

      // 存储第二个数组中元素的特定属性值
      const targetValues = arr2.map(item => item[key]);
      // 存储匹配的元素
      const matched = [];
      // 存储未匹配的元素
      const unmatched = [];

      // 遍历第一个数组，将匹配的元素和未匹配的元素分别存储
      for (const item of arr1) {
        if (targetValues.includes(item[key])) {
          matched.push(item);
        } else {
          unmatched.push(item);
        }
      }

      // 对匹配的元素进行排序，使其顺序与第二个数组一致
      const sortedMatched = targetValues.map(value => {
        return matched.find(item => item[key] == value);
      }).filter(item => item!== undefined);
      // 合并匹配的元素和未匹配的元素
      return [...sortedMatched, ...unmatched];
    },
    templateConfigurationMode(type) {
      // 开启配置模式
      if (type === 0) {
        this.config.isConfigMode = true
        this.$nextTick(() => {
          const tree = this.$refs.configTableListTree;
          this.config.configDefaultColumn.forEach((node) => {
            if (node.check) {
              tree.setChecked(node.prop, true);
            }
          });
        })
      }
      // 保存/修改配置模式
      else if (type === 1 || type === 2) {
        // 检测
        if (!this.config.configModeForm.name) {
          this.$message.error('请输入配置模板名称!');
          return
        }
        if (type === 2) {

          if (!this.config.configId) {
            this.$message.error('未检测出有配置相关模板并启用,请新增!');
            return
          }
        }
        this.$nextTick(() => {
          let submitTable = myJsonClon.getClone(this.config.configModeForm)
          submitTable.tableId = this.config.configTableId
          submitTable.id = type === 2 ? this.config.configId : undefined
          const tableRef = this.$refs.tableRef;
          let tmsTabulationConfigValueList = []
          tableRef.columns.forEach((x,index) => {
            tmsTabulationConfigValueList.push({label: x.label,prop: x.property,fixed: x.fixed,width: x.width,sort: index})
          })
          submitTable.tmsTabulationConfigValueList = tmsTabulationConfigValueList;
          this.$http({
            url: this.$http.adornUrl(type === 1 ? '/tabulation/add' : '/tabulation/update'),
            method: type === 1 ? 'post' : 'put',
            data: submitTable
          }).then(async ({ data }) => {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
            })
            window.location.reload()
          })

        })
      }
    },
    getFormatter(formatterName) {
      if (formatterName) {
        // 首先尝试从组件的 methods 中获取方法
        let method = this[formatterName];
        if (typeof method === 'function') {
          return method;
        }
        // 若组件的 methods 中没有，再尝试从 Vue.prototype 中获取方法
        method = Vue.prototype[formatterName];
        if (typeof method === 'function') {
          return method;
        }
      }
      // 若都没找到，返回一个直接返回原始值的函数
      return (row, column, cellValue) => cellValue;
    },
    checkAllNodes (isChecked) {
      this.$nextTick(() => {
        if (isChecked) {
          this.$refs.configTableListTree.setCheckedNodes(this.config.configDefaultPropColumn)
        } else {
          this.$refs.configTableListTree.setCheckedKeys([])
          this.$forceUpdate()
        }
      })
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      const tree = this.$refs.configTableListTree;
      tree.setChecked(draggingNode.data.prop, draggingNode.data.check);
    },
    handleCheckChange(data, checked, indeterminate) {
      const targetElement = this.config.configDefaultColumn.find(item => item.prop === data.prop);
      targetElement.check = checked
    },
    allowDrag(draggingNode) {
      return draggingNode.data.fixed ? false : true
    },
    allowDrop(draggingNode, dropNode, type) {
      return type === 'inner' ? false : true
    },
    // -----------------动态表格模式end-----------------

    // 已报出
    entranceOut () {
      if (this.dataListSelections.length === 0) {
        this.$message.error('请选择要报出的订单')
        return
      }
      const ids = this.dataListSelections.flatMap(item => item.orderInfo.id)
      this.$http({
        url: this.$http.adornUrl('/order/entranceOut'),
        method: 'post',
        data: ids
      }).then(({ data }) => {
        if (data) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.getDataList()
            }
          })
        } else {
          this.$message.error('操作失败')
        }
      })
    },
    // 已报入
    entranceIn () {
      if (this.dataListSelections.length === 0) {
        this.$message.error('请选择要报入的订单')
        return
      }
      const ids = this.dataListSelections.flatMap(item => item.orderInfo.id)
      this.$http({
        url: this.$http.adornUrl('/order/entranceIn'),
        method: 'post',
        data: ids
      }).then(({ data }) => {
        if (data) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.getDataList()
            }
          })
        } else {
          this.$message.error('操作失败')
        }
      })
    },
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    // 查看照片
    viewPhoto (row, typeList) {
      this.$refs.viewPhoto.init(row.orderInfo.id, typeList)
    },
    // 轨迹查看
    trackView (row) {
      this.$http({
        url: this.$http.adornUrl(`/order/getToMapTrajectoryUrl/${row.orderInfo.id}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        // window.open(data)
        this.$refs.track.init(data)
      }).catch(() => {})
    },
    setDefaultDate () {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24)
      end.setTime(end.getTime() + 3600 * 1000 * 24)
      this.dataForm.takeGoodsTime = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    importFile () {
      this.$refs.drawer.init()
    },
    customerCostDetail (type, todo, row) {
      this.$refs.customerCost.init(type, todo, row, 1)
    },
    isWithinFiveMinutes (time1, time2) {
      const one = new Date(time1).getTime()
      const two = new Date(time2).getTime()
      const fiveMinutes = 3 * 60 * 1000
      return Math.abs(one - two) < fiveMinutes
    },
    downLoad () {
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/order/orderExport'),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'listType': 1,
          'inWarehouseNo': this.dataForm.inWarehouseNo,
          'customerId': this.dataForm.customerId,
          'customerName': this.dataForm.customerName,
          'status': this.dataForm.status,
          'customerSelfNo': this.dataForm.customerSelfNo,
          'customerContactName': this.dataForm.customerContactName,
          'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
          'takeGoodsEndDate': timeParam.takeGoodsEndDate
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '订单列表(客户).xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 获取附件信息
    getFileData (data) {
      if (data) {
        this.$message({
          message: '导入成功',
          type: 'success',
          duration: 1500,
          onClose: () => {
            this.getDataList()
          }
        })
      } else {
        this.$message.error('操作失败')
      }
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      let param = {
        'page': this.pageIndex,
        'limit': this.pageSize,
        'inWarehouseNo': this.dataForm.inWarehouseNo,
        'customerId': this.dataForm.customerId,
        'customerName': this.dataForm.customerName,
        'status': this.dataForm.status,
        'customerSelfNo': this.dataForm.customerSelfNo,
        'customerContactName': this.dataForm.customerContactName,
        'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
        'takeGoodsEndDate': timeParam.takeGoodsEndDate,
        'vehiclePlate': this.dataForm.vehiclePlate,
        'customerIdList': this.dataForm.customerIds
      }
      const params = qs.stringify(param, { arrayFormat: 'repeat' })
      this.$http({
        url: this.$http.adornUrl('/order/findCustomerList?' + params),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data) {
          const columns = this.$refs.tableRef.columns.map(column => column.property).filter(item => !!item)
          this.getSpanArr(data.list, columns)
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (id) {
      this.detailVisible = true
      this.$nextTick(() => {
        this.$refs.detail.init(id)
      })
    },
    // 复制数据
    copyInfo (row) {
      this.$router.push({
        path: '/order-create-special',
        query: {
          id: row.orderInfo.id
        }
      })
    },
    // 取消订单
    cancelOrder () {
      this.$refs.cancel.init()
    },
    // 新增 / 修改
    addOrUpdateHandle (row, todo) {
      this.$router.push({
        name: 'carpoolEditDetail',
        params: {
          id: row.orderInfo.id,
          listType: this.listType,
          todo: todo
        }
      })
    },
    // 修改状态
    updateStatus (row, title, status) {
      this.$confirm('确定对【' + row.orderInfo.orderNo + '】进行【' + title + '】?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/order/updateStatusById'),
          method: 'post',
          data: {
            id: row.orderInfo.id,
            status: status
          }
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    },
    // 删除
    deleteHandle (row) {
      this.$confirm(`确定对【${row.orderInfo.orderNo}】进行【删除】操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/order/delete/' + row.orderInfo.id),
          method: 'delete'
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style lang="less">
div#pane-order-list-customer {
  // 状态为已取消时置灰
  .el-table .cancel-row {
    background: #f6f6f6 !important;
  }
  .el-table .cancel-row:hover>td {
    background: #e6e6e6 !important;
  }
  .el-table .cell {
      white-space: nowrap;
  }
}
</style>
